// app/contexts/ConsentContext.tsx
'use client';

import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';

// Cookie name for storing consent preferences
export const CONSENT_COOKIE_NAME = 'guideful-consent';
const COOKIE_EXPIRY_DAYS = 365;

// Service configuration
export interface ConsentService {
  id: string;
  name: string;
  description: string;
  category: 'technical' | 'marketing';
}

// The services we track consent for
const CONSENT_SERVICES: ConsentService[] = [
  {
    id: 'session',
    name: 'Session Management',
    description: 'Notwendig für die Benutzeranmeldung und Sitzungsverwaltung.',
    category: 'technical'
  },
  {
    id: 'csrf',
    name: 'CSRF Protection',
    description: 'Schutz vor Cross-Site Request Forgery Angriffen.',
    category: 'technical'
  },
  {
    id: 'consent',
    name: 'Cookie-Einstellungen',
    description: 'Speichert Ihre Cookie-Präferenzen.',
    category: 'technical'
  },
  {
    id: 'posthog',
    name: 'PostHog Analytics',
    description: 'Analysiert Websitenutzung zur Verbesserung unserer Dienste.',
    category: 'marketing'
  },
  {
    id: 'facebook',
    name: 'Meta Pixel',
    description: 'Misst die Effektivität von Werbeanzeigen und verbessert unser Angebot.',
    category: 'marketing'
  },
  {
    id: 'googleAds',
    name: 'Google Ads',
    description: 'Verfolgt Conversion-Ereignisse für Werbezwecke.',
    category: 'marketing'
  }
];

// Default consent values - technical is always required, marketing is opt-in
const DEFAULT_CONSENTS = {
  technical: true,
  marketing: false
};

// Context state type
interface ConsentContextType {
  isInitialized: boolean;
  showBanner: boolean;
  consents: {
    technical: boolean;
    marketing: boolean;
  };
  services: ConsentService[];
  updateConsent: (category: 'technical' | 'marketing', value: boolean) => void;
  acceptAll: () => void;
  acceptRequired: () => void;
  saveConsents: () => void;
  openConsentModal: () => void;
  closeConsentModal: () => void;
}

// Create context with default values
const ConsentContext = createContext<ConsentContextType>({
  isInitialized: false,
  showBanner: false,
  consents: DEFAULT_CONSENTS,
  services: CONSENT_SERVICES,
  updateConsent: () => {},
  acceptAll: () => {},
  acceptRequired: () => {},
  saveConsents: () => {},
  openConsentModal: () => {},
  closeConsentModal: () => {},
});

// Helper function to save consent to cookie
function saveConsentToCookie(consents: { technical: boolean; marketing: boolean }) {
  const expiryDate = new Date();
  expiryDate.setDate(expiryDate.getDate() + COOKIE_EXPIRY_DAYS);

  document.cookie = `${CONSENT_COOKIE_NAME}=${JSON.stringify(
    consents
  )}; expires=${expiryDate.toUTCString()}; path=/; SameSite=Lax`;

  // Dispatch a custom event for other components to react to
  const event = new CustomEvent('consent-updated', { detail: consents });
  window.dispatchEvent(event);
}

interface ConsentProviderProps {
  children: ReactNode;
}

// Provider component
export function ConsentProvider({ children }: ConsentProviderProps) {
  const [isInitialized, setIsInitialized] = useState(false);
  const [showBanner, setShowBanner] = useState(false);
  const [consents, setConsents] = useState<{ technical: boolean; marketing: boolean }>(DEFAULT_CONSENTS);

  // Initialize consent state from cookie or defaults
  useEffect(() => {
    if (typeof window === 'undefined') return;

    console.log('Loading consent from cookie...');
    const cookieValue = document.cookie
      .split('; ')
      .find(row => row.startsWith(`${CONSENT_COOKIE_NAME}=`));

    if (cookieValue) {
      try {
        const cookieString = cookieValue.split('=')[1];
        console.log('Found consent cookie:', cookieString);
        const savedConsents = JSON.parse(decodeURIComponent(cookieString));
        console.log('Parsed consent value:', savedConsents);

        // Ensure technical is always true (required)
        const validatedConsents = {
          ...savedConsents,
          technical: true // Always enforce technical as true
        };

        setConsents(validatedConsents);
        setShowBanner(false);
      } catch (error) {
        console.error('Error parsing consent cookie:', error);
        setShowBanner(true);
      }
    } else {
      console.log('No consent cookie found, showing banner');
      setShowBanner(true);
    }

    // Mark initialization as complete
    setIsInitialized(true);
  }, []);

  // Update a specific consent category
  const updateConsent = (category: 'technical' | 'marketing', value: boolean) => {
    if (category === 'technical' && !value) {
      // Technical consent cannot be disabled
      return;
    }

    setConsents(prev => ({
      ...prev,
      [category]: value
    }));
  };

  // Accept all consent options
  const acceptAll = () => {
    const allConsents = {
      technical: true,
      marketing: true
    };
    setConsents(allConsents);
    saveConsentToCookie(allConsents);
    setShowBanner(false);
  };

  // Accept only required consents
  const acceptRequired = () => {
    const requiredConsents = {
      technical: true,
      marketing: false
    };
    setConsents(requiredConsents);
    saveConsentToCookie(requiredConsents);
    setShowBanner(false);
  };

  // Save current consent selections
  const saveConsents = () => {
    saveConsentToCookie(consents);
    setShowBanner(false);
  };

  // Open consent modal
  const openConsentModal = () => {
    setShowBanner(true);
  };

  // Close consent modal
  const closeConsentModal = () => {
    setShowBanner(false);
  };

  // Context value
  const contextValue = {
    isInitialized,
    showBanner,
    consents,
    services: CONSENT_SERVICES,
    updateConsent,
    acceptAll,
    acceptRequired,
    saveConsents,
    openConsentModal,
    closeConsentModal
  };

  return (
    <ConsentContext.Provider value={contextValue}>
      {children}
    </ConsentContext.Provider>
  );
}

// Hook to use the consent context
export function useConsent() {
  const context = useContext(ConsentContext);
  if (context === undefined) {
    throw new Error('useConsent must be used within a ConsentProvider');
  }
  return context;
}