'use client';

import { useRef, useState, useEffect, ReactNode } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid';

interface CategoryBarArrowsProps {
  children: ReactNode;
  containerId: string;
}

const CategoryBarArrows: React.FC<CategoryBarArrowsProps> = ({ children, containerId }) => {
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(true);
  const [container, setContainer] = useState<HTMLElement | null>(null);

  useEffect(() => {
    // Find the container after mounting
    const scrollContainer = document.getElementById(containerId);
    if (scrollContainer) {
      setContainer(scrollContainer);

      const handleScroll = () => {
        const { scrollLeft, scrollWidth, clientWidth } = scrollContainer;

        // Show left arrow if scrolled to the right
        setShowLeftArrow(scrollLeft > 0);

        // Show right arrow if there's more content to scroll
        setShowRightArrow(scrollLeft + clientWidth < scrollWidth - 10); // 10px tolerance
      };

      scrollContainer.addEventListener('scroll', handleScroll);
      // Initial check for arrow visibility
      handleScroll();

      // Check if there's enough content to be scrollable
      if (scrollContainer.scrollWidth <= scrollContainer.clientWidth) {
        setShowRightArrow(false);
      }

      return () => {
        scrollContainer.removeEventListener('scroll', handleScroll);
      };
    }
  }, [containerId]);

  const scrollLeft = () => {
    if (container) {
      container.scrollBy({ left: -200, behavior: 'smooth' });
    }
  };

  const scrollRight = () => {
    if (container) {
      container.scrollBy({ left: 200, behavior: 'smooth' });
    }
  };

  return (
    <>
      {/* Left navigation arrow */}
      {showLeftArrow && (
        <button
          onClick={scrollLeft}
          className="absolute left-0 top-1/2 -translate-y-1/2 z-10 bg-white/80 hover:bg-white shadow-md rounded-full p-2 backdrop-blur-sm transition-all"
          aria-label="Vorherige Kategorien"
        >
          <ChevronLeftIcon className="w-6 h-6 text-gray-700" />
        </button>
      )}

      {/* Content passed from the parent */}
      {children}

      {/* Right navigation arrow */}
      {showRightArrow && (
        <button
          onClick={scrollRight}
          className="absolute right-0 top-1/2 -translate-y-1/2 z-10 bg-white/80 hover:bg-white shadow-md rounded-full p-2 backdrop-blur-sm transition-all"
          aria-label="Weitere Kategorien"
        >
          <ChevronRightIcon className="w-6 h-6 text-gray-700" />
        </button>
      )}
    </>
  );
};

export default CategoryBarArrows;