// components/tracking/ClientSideTracking.tsx
'use client';

import { useEffect } from 'react';
import MetaPixelTracker from './MetaPixelTracker';
import GoogleAdTracker from './GoogleAdsTracker';
import { useConfigStore } from '@/app/lib/stores/configStore';
import { useConsentCheck } from '@/app/hooks/useConsentCheck';

type EventType = 'ViewContent' | 'Purchase' | 'ViewItemList' | 'PageView';

interface ClientSideTrackingProps {
  eventType: EventType;
  orderId?: string;
  productId?: string;
  price?: string;
  itemName?: string;
  itemCategory?: string;
}

export default function ClientSideTracking({
                                             eventType,
                                             orderId,
                                             productId,
                                             price = '0',
                                             itemName,
                                             itemCategory,
                                           }: ClientSideTrackingProps) {
  const { debugMode, isInitialized, initialize } = useConfigStore();
  const { hasConsent: hasFacebookConsent } = useConsentCheck('facebook');
  const { hasConsent: hasGoogleAdsConsent } = useConsentCheck('googleAds');
  const numericPrice = parseFloat(price);

  useEffect(() => {
    if (!isInitialized) {
      initialize();
    }
  }, [isInitialized, initialize]);

  if (debugMode || !isInitialized) {
    return null;
  }

  return (
    <>
      {hasFacebookConsent && eventType === 'ViewContent' && productId && (
        <MetaPixelTracker
          eventType="ViewContent"
          contentIds={[productId]}
          value={numericPrice}
          currency="EUR"
          eventId={orderId || productId}
        />
      )}

      {hasGoogleAdsConsent && (
        <GoogleAdTracker
          eventType={eventType}
          value={price}
          currency="EUR"
          eventId={orderId || productId}
          itemName={itemName}
          itemCategory={itemCategory}
        />
      )}
    </>
  );
}
