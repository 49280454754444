import { create } from 'zustand';

interface ConfigStore {
  debugMode: boolean;
  stripeKey: string;
  isLoading: boolean;
  isInitialized: boolean; // New flag to track initialization
  error: string | null;
  initialize: () => Promise<void>;
}

export const useConfigStore = create<ConfigStore>((set) => ({
  debugMode: false,
  stripeKey: '',
  isLoading: false,
  isInitialized: false, // Start with false
  error: null,

  initialize: async () => {
    set({ isLoading: true });

    try {
      const res = await fetch('/api/config/stripe');

      if (!res.ok) {
        throw new Error('Fehler beim Laden der Konfiguration');
      }

      const data = await res.json();
      const debugMode = data.debugMode === 'true';

      set({
        stripeKey: data.stripeKey || '',
        debugMode: debugMode,
        isLoading: false,
        isInitialized: true // Mark as initialized
      });
    } catch (error) {
      console.error('Konfigurationsfehler:', error);
      set({
        error: error instanceof Error ? error.message : 'Unbekannter Fehler',
        isLoading: false,
        isInitialized: true // Mark as initialized even on error
      });
    }
  }
}));