'use client';

import Image from 'next/image';
import Link from 'next/link';
import {GroupEventDisplayProduct} from '@/app/api/types';
import {ClockIcon, UserGroupIcon, VideoCameraIcon} from '@heroicons/react/24/outline';
import {Award} from "flowbite-react-icons/outline";
import {formatInTimeZone} from 'date-fns-tz';
import LoadingSpinner from "@/components/ui/LoadingSpinner";
import {generateGuideSlug} from "@/app/lib/slugUtil";
import {useFeatureFlag} from '@/app/hooks/useFeatureFlag';

interface GroupProductGridProps {
  products: GroupEventDisplayProduct[];
  loading: boolean;
}

function GroupProductCard({product}: { product: GroupEventDisplayProduct }) {
  const formatEventDate = (isoString: string) => {
    return formatInTimeZone(
      new Date(isoString),
      'Europe/Berlin',
      "dd.MM.yyyy 'um' HH:mm 'Uhr'"
    );
  };

  const futureSchedules = product.schedules
    .filter(schedule => new Date(schedule.startTime) > new Date())
    .sort((a, b) => new Date(a.startTime).getTime() - new Date(b.startTime).getTime());

  const nextSchedule = futureSchedules[0];
  const additionalSchedules = futureSchedules.length - 1;

  if (!nextSchedule) return null;

  return (
    <div className="bg-white border border-gray-200 rounded-lg group flex flex-col">
      <div className="relative w-full h-48">
        <Image
          src={product.teaser_img || '/images/lp/cards/placeholder-image.jpg'}
          alt={product.name || 'Event Vorschaubild'}
          fill
          style={{objectFit: 'cover'}}
          sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
          className="rounded-t-lg"
        />
        {product.featured && (
          <div
            className="absolute top-2 right-2 bg-primary-500 text-white px-3 py-1.5 rounded-full text-sm flex items-center gap-1">
            <Award className="w-4 h-4"/>
            <span>Featured</span>
          </div>
        )}

        <div className="absolute -bottom-8 left-4">
          <Link href={`/guide/${generateGuideSlug(`${product.guide.name}`, product.guide.id)}`}>
            <div className="w-16 h-16 rounded-full border-4 border-white overflow-hidden relative">
              <Image
                src={product.guide?.profileImage || '/images/guide/placeholder.webp'}
                alt={product.guide?.name || 'Guide Profilbild'}
                fill
                sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
                className="object-cover"
              />
            </div>
          </Link>
        </div>
      </div>

      <div className="pt-8 px-4 pb-4 flex flex-col flex-grow">
        <div className="mb-0">
          <Link
            href={`/guide/${generateGuideSlug(product.guide?.name || '', product.guideId)}`}
            className="text-sm font-bold text-gray-600 hover:text-gray-900 transition-colors"
          >
            {product.guide?.name}
          </Link>
        </div>

        <div className="flex-grow">
          <h5 className="mb-2 text-lg md:text-xl font-semibold tracking-tight text-gray-900 line-clamp-2"
              style={{lineHeight: "1.0"}}>
            {product.name}
          </h5>

          <p className="mb-3 text-sm md:text-sm text-gray-700 line-clamp-4">
            {product.description}
          </p>

          <div className="flex items-center gap-4 mb-3">
            <div className="flex items-center text-gray-600">
              <VideoCameraIcon className="w-5 h-5 mr-1"/>
              <span className="text-sm">Video-Gruppenevent</span>
            </div>
            <div className="flex items-center text-gray-600">
              <ClockIcon className="w-5 h-5 mr-1"/>
              <span className="text-sm">{product.duration} Minuten</span>
            </div>
          </div>

          {product.category_id && (
            <div className="flex flex-wrap items-center mt-2 -m-1">
              {product.categories?.map(category => (
                <Link
                  href={`/category/${category.id}`}
                  key={category.id}
                  className="bg-white border-gray-200 border-2 text-primary-200 text-xs font-medium m-1 px-2.5 py-0.5 rounded-full hover:text-primary-dark hover:border-primary-dark transition-colors duration-200"
                >
                  {category.name}
                </Link>
              ))}
            </div>
          )}

          <div className="mt-4 space-y-2">
            <h6 className="font-medium text-gray-900">Nächster Termin:</h6>
            <div
              className="bg-white border border-primary-200 p-3 rounded-lg hover:border-primary-300 transition-colors">
              <div className="flex justify-between items-center mb-1">
                <span className="text-sm font-medium">
                  {formatEventDate(nextSchedule.startTime)}
                </span>
                <span className="text-sm text-gray-600">
                  {nextSchedule.pricePerSeat} €
                </span>
              </div>
              <div className="flex items-center text-sm text-gray-600">
                <UserGroupIcon className="w-4 h-4 mr-1"/>
                <span>
                  {nextSchedule.currentParticipantCount ?? 0}/{nextSchedule.maxParticipants} Teilnehmer
                </span>
              </div>
            </div>
            {additionalSchedules > 0 && (
              <p className="text-sm text-gray-500 italic">
                +{additionalSchedules} weitere Termine
              </p>
            )}
          </div>
        </div>

        <Link href={`/group-event/${product.id}`} className="block">
          <button className="
            mt-4
            w-full
            bg-primary
            hover:bg-primary-dark
            text-white
            px-2
            pt-1
            h-10
            font-bold
            rounded-full
            cursor-pointer
            transition-colors
            duration-200
            flex items-center justify-center
          ">
            DEIN NÄCHSTER SCHRITT
          </button>
        </Link>
      </div>
    </div>
  );
}

export function GroupProductGrid({products, loading}: GroupProductGridProps) {
  // Use the feature flag hook to check if the feature is enabled
  const isVideoGroupEventsEnabled = useFeatureFlag("videoGroupEvents");

  // If feature is disabled, don't render anything
  if (!isVideoGroupEventsEnabled) {
    return null;
  }

  // Existing loading check
  if (loading) {
    return <div className="flex justify-center py-8"><LoadingSpinner size="lg"/></div>;
  }

  // Filter for valid products (with future schedules)
  const validProducts = products.filter(product =>
    product.schedules.some(schedule => new Date(schedule.startTime) > new Date())
  );

  // Don't render if no valid products
  if (validProducts.length === 0) {
    return null;
  }

  return (
    <section className="max-w-7xl mx-auto py-2">
      <h2 className="text-2xl md:text-3xl font-bold mb-4">Aktuelle Gruppenevents</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {validProducts.map((product) => (
          <GroupProductCard key={product.id} product={product}/>
        ))}
      </div>
    </section>
  );
}