// app/hooks/useFeatureFlag.ts
'use client';

import { useState, useEffect } from 'react';
import { remoteConfig } from '@/app/lib/firebase/firebase';
import { fetchAndActivate, getValue } from 'firebase/remote-config';
import { useConfigStore } from '@/app/lib/stores/configStore';

/**
 * Hook to check if a feature flag is enabled via Firebase Remote Config
 * @param featureName The name of the feature flag to check
 * @param defaultValue Optional default value if the flag can't be retrieved (defaults to false)
 * @returns Boolean indicating if the feature is enabled
 */
export function useFeatureFlag(featureName: string, defaultValue: boolean = false): boolean {
  const [isEnabled, setIsEnabled] = useState<boolean>(defaultValue);
  const { debugMode, isInitialized, isLoading } = useConfigStore();

  useEffect(() => {
    // Skip effect if config store is still initializing
    if (!isInitialized) {
      return;
    }

    const checkFeature = async () => {
      try {
        // If in debug mode, enable all features
        if (debugMode) {
          console.log(`Debug mode enabled, enabling feature: ${featureName}`);
          setIsEnabled(true);
          return;
        }

        // Otherwise check Firebase Remote Config
        await fetchAndActivate(remoteConfig);
        const featureValue = getValue(remoteConfig, featureName).asBoolean();
        setIsEnabled(featureValue);
      } catch (error) {
        console.error(`Error checking feature flag ${featureName}:`, error);
        // Fall back to default value on error
        setIsEnabled(defaultValue);
      }
    };

    checkFeature();
  }, [featureName, defaultValue, debugMode, isInitialized]);

  // While config is loading, return the default value
  if (!isInitialized) {
    return defaultValue;
  }

  return isEnabled;
}

/**
 * Hook to check if a feature flag is enabled with loading state awareness
 * @param featureName The name of the feature flag to check
 * @param defaultValue Optional default value if the flag can't be retrieved (defaults to false)
 * @returns Object with isEnabled boolean and isLoading state
 */
export function useFeatureFlagWithLoading(featureName: string, defaultValue: boolean = false):
  { isEnabled: boolean; isLoading: boolean } {
  const [isEnabled, setIsEnabled] = useState<boolean>(defaultValue);
  const [isLoadingFlag, setIsLoadingFlag] = useState<boolean>(true);
  const { debugMode, isInitialized, isLoading: configLoading } = useConfigStore();

  useEffect(() => {
    // Skip effect if config store is still initializing
    if (!isInitialized) {
      return;
    }

    setIsLoadingFlag(true);
    const checkFeature = async () => {
      try {
        // If in debug mode, enable all features
        if (debugMode) {
          setIsEnabled(true);
          return;
        }

        // Otherwise check Firebase Remote Config
        await fetchAndActivate(remoteConfig);
        const featureValue = getValue(remoteConfig, featureName).asBoolean();
        setIsEnabled(featureValue);
      } catch (error) {
        console.error(`Error checking feature flag ${featureName}:`, error);
        // Fall back to default value on error
        setIsEnabled(defaultValue);
      } finally {
        setIsLoadingFlag(false);
      }
    };

    checkFeature();
  }, [featureName, defaultValue, debugMode, isInitialized]);

  return {
    isEnabled,
    isLoading: isLoadingFlag || !isInitialized || configLoading
  };
}