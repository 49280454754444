// app/lib/slugUtil.ts

/**
 * Utility functions for handling guide profile slugs
 */

/**
 * Converts a string to a URL-friendly slug
 * @param name The name to convert to a slug
 * @returns A URL-friendly version of the name
 */
export function nameToSlug(name: string): string {
  return name
    .toLowerCase()
    .trim()
    .replace(/ä/g, 'ae')
    .replace(/ö/g, 'oe')
    .replace(/ü/g, 'ue')
    .replace(/ß/g, 'ss')
    .replace(/[^\w\s-]/g, '') // Remove special characters
    .replace(/\s+/g, '-') // Replace spaces with hyphens
    .replace(/-+/g, '-') // Replace multiple hyphens with single hyphen
    .substring(0, 70); // Leave room for ID suffix
}


/**
 * Generates a unique slug for a guide using their full name and ID
 * @param fullName Guide's full name or combined first and last name
 * @param id Guide's full MongoDB ObjectId
 * @returns A unique slug combining the name and ID
 */
export function generateGuideSlug(fullName: string, id: string): string {
  const baseSlug = nameToSlug(fullName);
  return `${baseSlug}-${id}`;
}

/**
 * Extracts the MongoDB ObjectId from a slug
 * @param slug The full slug (e.g., "john-doe-507f1f77bcf86cd799439011")
 * @returns The MongoDB ObjectId or null if not found
 */
export function extractIdFromSlug(slug: string): string | null {
  const match = slug.match(/-([0-9a-fA-F]{24})$/);
  return match ? match[1] : null;
}

/**
 * Extracts the ID suffix from a slug
 * @param slug The full slug (e.g., "john-doe-1234")
 * @returns The ID suffix or null if not found
 */
export function extractIdSuffixFromSlug(slug: string): string | null {
  const match = slug.match(/-([a-f0-9]{4})$/);
  return match ? match[1] : null;
}

/**
 * Gets the guide's full MongoDB ObjectId from a slug or partial ID
 * @param slug The slug or partial ID to resolve
 * @returns Promise resolving to the full guide ID
 * @throws Error if the slug is invalid or guide not found
 */
export async function getGuideIdFromSlug(slug: string): Promise<string> {
  // If it's already a full MongoDB ObjectId, return it
  if (/^[0-9a-fA-F]{24}$/.test(slug)) {
    return slug;
  }

  // Extract the ID suffix from the slug
  const idSuffix = extractIdSuffixFromSlug(slug);
  if (!idSuffix) {
    throw new Error('Ungültiger Guide-Link');
  }

  try {
    // Use authenticatedFetch to get the full guide ID from the backend
    const response = await fetch(`/api/guide/resolve-slug/${idSuffix}`);
    if (!response.ok) {
      throw new Error('Guide nicht gefunden');
    }

    const data = await response.json();
    return data.guideId;
  } catch (error) {
    console.error('Error resolving guide ID from slug:', error);
    throw new Error('Guide nicht gefunden');
  }
}