// components/tracking/MetaPixelTracker.tsx
'use client';

import { useEffect } from 'react';
import { useConsentCheck } from '@/app/hooks/useConsentCheck';

interface MetaPixelTrackerProps {
  eventType: 'ViewContent' | 'Purchase';
  contentIds: string[];
  value?: number;
  currency?: string;
  eventId?: string;
}

export default function MetaPixelTracker({
                                           eventType,
                                           contentIds,
                                           value,
                                           currency = 'EUR',
                                           eventId
                                         }: MetaPixelTrackerProps) {
  const { hasConsent } = useConsentCheck('facebook');

  useEffect(() => {
    if (typeof window === 'undefined' || !hasConsent) return;

    // Adding delay to ensure tracking libraries are fully loaded
    const timer = setTimeout(() => {
      console.log('MetaPixelTracker: Attempting to track event', eventType);

      if (eventType === 'ViewContent' && window.trackViewContent) {
        console.log('MetaPixelTracker: Tracking ViewContent with data:', { contentIds, eventId });
        window.trackViewContent(contentIds, 'product', eventId);
      } else if (eventType === 'Purchase' && window.trackPurchase && value !== undefined) {
        console.log('MetaPixelTracker: Tracking Purchase with data:', { contentIds, value, currency, eventId });
        window.trackPurchase(contentIds, value, currency, 'product', eventId);
      } else {
        console.error('MetaPixelTracker: Required tracking function not available or missing value parameter');
      }
    }, 500);

    return () => clearTimeout(timer);
  }, [eventType, contentIds, value, currency, eventId, hasConsent]);

  return null;
}