import { initializeApp } from "firebase/app";
import { getRemoteConfig, fetchAndActivate, getValue } from "firebase/remote-config";

// 🔹 Hier deine echten Firebase-Werte einfügen!
const firebaseConfig = {
  apiKey: "AIzaSyDV9sBWhFY7mpxc0VCMbSqRRre8ZEphtlw",
  authDomain: "guideful-7675e.firebaseapp.com",
  projectId: "guideful-7675e",
  storageBucket: "guideful-7675e.firebasestorage.app",
  messagingSenderId: "126382883705",
  appId: "1:126382883705:web:27dc9c1e1fccc668d2e5a8",
  measurementId: "G-XHLHKXWQ7H"
};

// Firebase App initialisieren (nur einmal pro Instanz)
const app = initializeApp(firebaseConfig);
const remoteConfig = getRemoteConfig(app);
remoteConfig.settings.minimumFetchIntervalMillis = 60000; // 1 Min. Cache

export { remoteConfig };
