// components/tracking/GoogleAdsTracker.tsx
'use client';

import { useEffect } from 'react';
import { useConsentCheck } from '@/app/hooks/useConsentCheck';

type GoogleEventType = 'ViewContent' | 'Purchase' | 'ViewItemList' | 'PageView';

interface GoogleAdsTrackerProps {
  eventType: GoogleEventType;
  value?: string;
  currency?: string;
  eventId?: string;
  itemName?: string;
  itemCategory?: string;
}

export default function GoogleAdsTracker({
                                           eventType,
                                           value = '0',
                                           currency = 'EUR',
                                           eventId,
                                           itemName,
                                           itemCategory,
                                         }: GoogleAdsTrackerProps) {
  const { hasConsent } = useConsentCheck('googleAds');

  useEffect(() => {
    if (typeof window === 'undefined' || !hasConsent) return;

    const hasGclid = (): boolean => {
      return window.location.href.indexOf('gclid=') !== -1;
    };

    if (eventType === 'Purchase' && !hasGclid()) {
      console.log('GoogleAdsTracker: gclid fehlt, Purchase wird nicht getrackt.');
      return;
    }

    const timer = setTimeout(() => {
      if (typeof window.gtag !== 'function') {
        console.warn('GoogleAdsTracker: gtag nicht verfügbar.');
        return;
      }

      const googleEventTypeMap: Record<GoogleEventType, string> = {
        ViewContent: 'view_item',
        ViewItemList: 'view_item_list',
        Purchase: 'purchase',
        PageView: 'page_view',
      };

      const eventName = googleEventTypeMap[eventType];

      const isProductEvent = eventType === 'ViewContent' || eventType === 'Purchase';

      const eventData =
        eventType === 'Purchase'
          ? {
            send_to: 'AW-16942094959/5BT8CLL5q60aEO-0z44_',
            transaction_id: eventId,
            value,
            currency,
            ...(isProductEvent && itemName && { item_name: itemName }),
            ...(isProductEvent && itemCategory && { item_category: itemCategory }),
          }
          : {
            ...(eventId && { item_id: eventId }),
            value,
            currency,
            ...(isProductEvent && itemName && { item_name: itemName }),
            ...(isProductEvent && itemCategory && { item_category: itemCategory }),
          };

      console.log(`GoogleAdsTracker: sende ${eventName}`, eventData);
      window.gtag('event', eventName, eventData);
    }, 300);

    return () => clearTimeout(timer);
  }, [eventType, value, currency, eventId, itemName, itemCategory, hasConsent]);

  return null;
}
