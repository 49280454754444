// app/hooks/useConsentCheck.ts
'use client'

import { useEffect, useState } from 'react';
import { useConsent } from '@/app/contexts/ConsentContext';

/**
 * Custom hook to check consent for a specific service
 * Returns consent status and initialization status
 */
export function useConsentCheck(serviceType: 'facebook' | 'googleAds' | 'posthog') {
  const { consents, isInitialized } = useConsent();
  const [hasConsent, setHasConsent] = useState(false);

  // Map service to consent category
  const getCategory = () => {
    switch (serviceType) {
      case 'facebook':
      case 'googleAds':
      case 'posthog':
        return 'marketing';
      default:
        return null;
    }
  };

  // Update consent status when context initializes or changes
  useEffect(() => {
    if (isInitialized) {
      const category = getCategory();
      if (category) {
        setHasConsent(consents[category]);
      }
    }
  }, [isInitialized, consents, serviceType]);

  // Listen for consent updates (for immediate changes without re-render)
  useEffect(() => {
    const handleConsentUpdate = (e: CustomEvent) => {
      const category = getCategory();
      if (category && e.detail && typeof e.detail === 'object') {
        setHasConsent(e.detail[category]);
      }
    };

    window.addEventListener('consent-updated', handleConsentUpdate as EventListener);

    return () => {
      window.removeEventListener('consent-updated', handleConsentUpdate as EventListener);
    };
  }, [serviceType]);

  return { hasConsent, isInitialized };
}